.glide__track {
	/* background-image: url('/img/transparent.png'); */
}

.glide__arrow {
	@apply 
		text-white
		text-xl
		xl:text-3xl
		border-2 
		border-white 
		rounded-full 
		p-2 
		xl:p-3
		hover:bg-white 
		hover:text-gray-700 
		focus:outline-none;
	position: absolute;
	display: block;
	top: 50%;
	z-index: 9;
	cursor: pointer;
	transform: translateY(-50%);
	line-height: 0.95;
}

.glide__arrow2 {
	@apply focus:outline-none;
	position: absolute;
	top: 50%;
	height: 100%;
	transform: translateY(-50%);
	cursor: pointer;
	width: 50%;
}

.glide__arrow2--left {
	cursor: url('/img/cursors/left.png') 0 24, url('/img/cursors/left.cur'), w-resize;
}

.glide__arrow2--right {
	cursor: url('/img/cursors/right.png') 14 24, url('/img/cursors/right.cur'), e-resize;
	right: 0;
}

.glide__arrow--left {
	left: 1em;
}
.glide__arrow--right {
	right: 1em;
}
.glide__arrow--disabled {
}


.glide__bullets {
	position: absolute;
	z-index: 2;
	bottom: 1em;
	left: 50%;
	display: inline-flex;
	list-style: none;
	transform: translateX(-50%);
}

.glide__bullet {
	background-color: rgba(255, 255, 255, 0.5);
	width: 9px;
	height: 9px;
	padding: 0;
	border-radius: 50%;
	border: 2px solid transparent;
	transition: all 300ms ease-in-out;
	cursor: pointer;
	line-height: 0;
	box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
	margin: 0 0.25em;
}
.glide__bullet:focus {
	outline: none; }
.glide__bullet:hover, .glide__bullet:focus {
	border: 2px solid white;
	background-color: rgba(255, 255, 255, 0.5); }
.glide__bullet--active {
	background-color: white;
}

/*
.glide--swipeable {
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
}
*/

.glide--dragging {
	cursor: grabbing;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
}